import React from 'react';

interface IStatistics {
  membersQuantity: number | string;
}

const Statistics = ({ membersQuantity }: IStatistics) => {
  return (
    <div className="statistics">
      <div className="statistics__section">
        <div className="statistics__number">{membersQuantity}</div>
        <p className="statistics__text">members</p>
      </div>
      <div className="statistics__section">
        <p className="statistics__number">190+</p>
        <p className="statistics__text">countries</p>
      </div>
    </div>
  );
};

export default Statistics;
